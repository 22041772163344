<template>
	<div class="prod-details-page">
		<div class="top-content" :class="{ 'wide-prod-img': wideProdImg }">
			<div class="content-inner">
				<back-button :back-url="props.backUrl" />
				<div class="prod-img-name-wrap">
					<div class="img-wrap">
						<div class="placeholder" v-if="modelLine.video && wideProdImg"></div>
						<slot name="modelImg" />
						<play-button class="dark-border small" v-if="modelLine.video && wideProdImg" @playBtnClicked="playVideo">
							<template #label>Watch in Action</template>
						</play-button>
					</div>
					<div class="model-line-name">
						<product-lines :line="props.prodLine" />
						<h1>
							<slot name="modelName" />
						</h1>
						<h2 v-if="slots['modelSubHeadline']">
							<slot name="modelSubHeadline" />
						</h2>
						<play-button class="small" v-if="modelLine.video && !wideProdImg" @playBtnClicked="playVideo">
							<template #label>Watch in Action</template>
						</play-button>
					</div>
				</div>
			</div>

			<div class="curve-border dir-down dots">
				<dots-background />
			</div>
		</div>

		<div class="cta-section">
			<div class="content-inner">
				<h2>
					<template v-if="slots['modelTagline1']">
						<slot name="modelTagline1" /><br>
					</template>
					<template v-if="slots['modelTagline2']">
						<slot name="modelTagline2" />
					</template>
				</h2>
				<p>
					<slot name="modelDescription" />
				</p>
				<div>
					<router-link class="cta-btn" :to="`/Contact?referrer=${props.prodLine} | ${slots.modelName()[0].children}`">
						Inquire Now
						<img src="@/images/icons/arrows-right.png" alt="">
					</router-link>
				</div>
			</div>

			<div class="curve-border dir-down green-pattern"></div>
		</div>

		<div class="spec-section">
			<div class="specs-header">
				<button type="button" class="carousel-nav" :class="{ clicked: clickedBtn === 'prev' }" @click="carouselPrev" @animationend="clickedBtn = ''">
					<img src="@/images/icons/arrows-left.png" alt="">
					<span>{{ carouselNavLabels[0] }}</span>
				</button>
				<button type="button" class="carousel-nav" :class="{ clicked: clickedBtn === 'next' }" @click="carouselNext" @animationend="clickedBtn = ''">
					<span>{{ carouselNavLabels[2] }}</span>
					<img src="@/images/icons/arrows-right.png" alt="">
				</button>
			</div>

			<Carousel class="details-carousel" ref="detailsCarousel" :settings="settings">
				<Slide :key="'features'">
					<div class="features-slide slide">
						<h2>Features</h2>
						<ul>
							<li v-for="feature in props.modelLine.features" :key="feature">
								<svg class="icon">
									<use :xlink:href="`#${feature.iconId}`"></use>
								</svg>
								<div class="feature-text" v-html="feature.text"></div>
							</li>
							<li v-if="(props.modelLine.features.length) % 2"></li>
						</ul>
					</div>
				</Slide>
				<Slide :key="'specs'">
					<div class="specs-slide slide">
						<h2>Specs</h2>
						<div class="select-contain">
							<div class="input-contain" :class="{ disabled: props.modelLine.models.length === 1 }">
								<select :disabled="props.modelLine.models.length === 1" @change="selectedModel = $event.target.value">
									<option v-for="model in props.modelLine.models" :value="model.modelNum" :key="model.modelNum">{{ model.modelNum }}</option>
								</select>
								<label>Model: <span>{{ selectedModel }}</span></label>
							</div>
						</div>
						<ul :class="{ 'full-width': props.specsFullWidth }">
							<template v-for="(spec, i) in selectedModelData.modelSpecs" :key="spec[i]">
								<li>
									<div class="spec-label">{{ selectedModelData.specLabels[i] }}</div>
									<div class="spec" v-if="(spec instanceof Measurement)">{{ spec.value.toLocaleString() }} <span class="units">{{ spec.units }}</span></div>
									<div class="spec" v-else v-html="spec"></div>
								</li>
							</template>
						</ul>
					</div>
				</Slide>
				<Slide :key="'options'">
					<div class="options-slide slide">
						<h2>Options</h2>
						<ul :class="{ 'full-width': props.modelLine.options.length <= 5 }">
							<li v-for="option in props.modelLine.options" :key="option">
								<img src="@/images/icons/check.png" alt="">
								<span>{{ option }}</span>
							</li>
							<li v-if="(props.modelLine.options.length) % 2"></li>
						</ul>
					</div>
				</Slide>
			</Carousel>
		</div>

		<template v-if="modelLine.video">
			<transition name="toggle-fade">
				<div v-show="open" class="modal-bg" @click="closeVideo"></div>
			</transition>

			<transition name="toggle-scale">
				<video v-show="open" class="modal-video" ref="popupVid" crossorigin="anonymous" playsinline controls preload="auto">
					<source :src="`/videos3/${modelLine.video}`" type="video/mp4" />
				</video>
			</transition>
		</template>
	</div>
</template>

<script setup>
import { Measurement } from '@/helpers/unitConversion';
import { computed, onMounted, ref, useSlots } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const props = defineProps({
	prodLine: {
		type: String,
		required: true,
	},
	backUrl: {
		type: String,
		required: false,
		default: '/'
	},
	modelLine: {
		type: Object,
		required: true,
	},
	wideProdImg: {
		type: Boolean,
		required: false,
	},
	specsFullWidth: {
		type: Boolean,
		required: false,
	}
})

const open = ref(false);
const popupVid = ref(null);
const slots = useSlots();

const selectedModel = ref(props.modelLine.models[0].modelNum);
const selectedModelData = computed(() => {
	let model = props.modelLine.models.find(x => x.modelNum === selectedModel.value);
	let emptyValues = model.modelSpecs.reduce((acc, curr, index) => {
		if (curr === '-') {
			acc.push(index);
		}
		return acc;
	}, []);
	model.modelSpecs = model.modelSpecs.filter((x, i) => !emptyValues.includes(i));
	model.specLabels = props.modelLine.labels.filter((x, i) => !emptyValues.includes(i));
	return model;
});

const detailsCarousel = ref(null);
const clickedBtn = ref('');
const settings = {
	itemsToShow: 1,
	transition: 500,
	wrapAround: true,
	mouseDrag: false,
	touchDrag: false,
};

const carouselNavLabels = ref([
	'Options',
	'Features',
	'Specs',
]);

function carouselPrev() {
	detailsCarousel.value.prev();
	clickedBtn.value = 'prev';
	carouselNavLabels.value.unshift(carouselNavLabels.value.pop());
}

function carouselNext() {
	detailsCarousel.value.next();
	clickedBtn.value = 'next';
	carouselNavLabels.value.push(carouselNavLabels.value.shift());
}

async function playVideo() {
	open.value = true;
	popupVid.value.play();
}

function closeVideo() {
	open.value = false;
	popupVid.value.pause();
	popupVid.value.currentTime = 0.0;
}

onMounted(() => {
	if (props.modelLine.video) {
		popupVid.value.load();
	}
})
</script>
