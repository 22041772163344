<template>
	<teleport to="body">
		<transition name="refresh-sw" appear>
			<div v-if="open" class="refresh-sw-prompt">
				<div>App update available!</div>
				<button class="btn-update" :disabled="refreshing" @click="refresh()">
					<span class="button-text" :class="{ hidden: refreshing }">Update</span>
					<span class="is-loading dots" v-if="refreshing">
						<span class="dot-1"></span>
						<span class="dot-2"></span>
						<span class="dot-3"></span>
					</span>
				</button>
			</div>
		</transition>
	</teleport>
</template>

<script setup>
import { useStore } from '@/store';
import { computed, ref } from 'vue';

const store = useStore();
const open = computed(() => store.serviceWorkerUpdate);
const refreshing = ref(false);

function refresh() {

	const broadcast = new BroadcastChannel('refresh');
	broadcast.postMessage({ type: 'skipWaiting', });
}

navigator.serviceWorker.addEventListener('controllerchange', () => {
	if (refreshing.value) return;
	refreshing.value = true;
	window.location.reload();
});
</script>
