import InstallPrompt from '@/views/_partials/InstallPrompt';
import RefreshServiceWorker from '@/views/_partials/RefreshServiceWorker';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import { useYupExtensions } from './helpers/yupExtensions';
import './registerServiceWorker';
import router from './router';
import { useStore } from './store';
import BackButton from './views/_partials/BackButton';
import DotsBackground from './views/_partials/DotsBackground';
import PlayButton from './views/_partials/PlayButton';
import ProductCatLinks from './views/_partials/ProductCatLinks';
import ProductDetails from './views/_partials/ProductDetails';
import ProductLines from './views/_partials/ProductLines';
import TouchIcon from './views/_partials/TouchIcon';
import VideoModal from './views/_partials/VideoModal';

useYupExtensions();

const app = createApp(App);
app.use(createPinia());
const store = useStore();
store.refreshSettings().then(() => {
	app.use(router);
	app.mount('#app');
});

app.component('BackButton', BackButton);
app.component('DotsBackground', DotsBackground);
app.component('InstallPrompt', InstallPrompt);
app.component('PlayButton', PlayButton);
app.component('ProductCatLinks', ProductCatLinks);
app.component('ProductDetails', ProductDetails);
app.component('ProductLines', ProductLines);
app.component('RefreshServiceWorker', RefreshServiceWorker);
app.component('TouchIcon', TouchIcon);
app.component('VideoModal', VideoModal);

// refresh settings when updated
const broadcast = new BroadcastChannel('settings');
broadcast.addEventListener('message', event => {
	if (event.data === 'update') {
		store.refreshSettings();
	}
});

window.addEventListener('beforeinstallprompt', (e) => {
	e.preventDefault();
	store.showInstallBanner = localStorage.getItem('pwaUserChoice') != 'no';
	store.installPrompt = e;
});

if (process.env.NODE_ENV !== 'development') {
	document.addEventListener('contextmenu', function (e) {
		e.preventDefault();
	}, false);
}
