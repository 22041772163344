<template>
	<button class="play-btn-contain" @click="emit('playBtnClicked')">
		<div class="play-btn" type="button">
			<svg class="icon touch-border">
				<use xlink:href="#touchCircle"></use>
			</svg>
			<img src="@/images/icons/play.png" alt="">
		</div>
		<div class="label" v-if="$slots['label']">
			<slot name="label" />
		</div>
	</button>
</template>

<script setup>
const emit = defineEmits(['playBtnClicked']);
</script>
