<template>
	<main>
		<router-view />
		<div class="hidden-btns">
			<button class="reload-btn" type="button" @dblclick="reload()"></button>
		</div>
	</main>
	<app-icons />
	<app-messages />
	<inactivity-reset-warning />
	<!-- <install-prompt /> -->
	<refresh-service-worker />
</template>

<script setup>
import AppIcons from '@/views/_partials/AppIcons.vue';
import AppMessages from './views/_partials/AppMessages.vue';
import InactivityResetWarning from './views/_partials/InactivityResetWarning.vue';

function reload() {
	window.location.reload()
}
</script>
