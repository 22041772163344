<template>
	<div class="alert" :class="[messageClosed ? 'animate-out' : 'animate-in', `alert-${message.type}`]">
		<svg class="icon type-icon">
			<use :xlink:href="`#msg-${message.type}`"></use>
		</svg>
		<span class="message">{{ message.message }}</span>
	</div>
</template>

<script>
import Message from '@/models/Message';
import { computed, ref, onMounted } from 'vue';

export default {
	props: {
		message: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const messageClosed = ref(false);
		const autoCloseStyle = computed(() => ({ animationDuration: Message.AUTO_CLOSE_DELAY + 'ms' }));
		onMounted(() => {
			if (props.message.autoClose) {
				setTimeout(() => {
					messageClosed.value = true;
				}, Message.AUTO_CLOSE_DELAY);
			}
		});
		return {
			messageClosed,
			autoCloseStyle,
		};
	},
};
</script>
