<template>
	<router-link class="prod-item" :to="props.url">
		<slot name="modelImg" />
		<div class="model-line-name-wrap">
			<div class="img-wrap">
				<product-lines :line="props.prodLine" />
				<img class="arrows" src="@/images/icons/arrows-right.png" alt="">
			</div>
			<h2>
				<slot name="modelName" />
			</h2>
		</div>
	</router-link>
</template>

<script setup>
const props = defineProps({
	prodLine: {
		type: String,
		required: true,
	},
	url: {
		type: String,
		required: true,
	}
})
</script>
