<template>
	<img class="prod-line" v-if="props.line === 'E-Brake'" src="@/images/eBrakeLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'H-Brake'" src="@/images/hBrakeLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'H-BrakeHD'" src="@/images/hBrakeHDLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'H-iBrake'" src="@/images/hiBrakeLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'MiniCell'" src="@/images/miniCellLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'MultiCell'" src="@/images/multiCellLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'M-Shear'" src="@/images/mShearLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'R-Brake'" src="@/images/rBrakeLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'RoboMate'" src="@/images/roboMateLogo.png" alt="">
	<img class="prod-line" v-else-if="props.line === 'ToolMate'" src="@/images/toolMateLogo.png" alt="">
</template>

<script setup>
const props = defineProps({
	line: {
		type: String,
		required: true,
	},
})
</script>
