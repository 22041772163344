<template>
	<router-link class="back-link" :to="props.backUrl">
		<img v-if="props.darkArrow" src="@/images/icons/arrows-back-dk-green.png" alt="">
		<img v-else src="@/images/icons/arrows-back.png" alt="">
		Back
	</router-link>
</template>

<script setup>
const props = defineProps({
	backUrl: {
		type: String,
		required: false,
		default: '/',
	},
	darkArrow: {
		type: Boolean,
		required: false,
	},
});
</script>
