import { useStore } from "@/store";

export class Measurement {
	constructor(dto) {
		this.value = dto && typeof dto === 'object' && typeof dto.value === 'number' ? dto.value : 0;
		this.units = dto && typeof dto === 'object' && typeof dto.units === 'string' ? dto.units : '';
	}
}

export function round(number, precision) {
	const x = parseInt('1' + '0'.repeat(Math.max(0, precision)));
	return Math.round(number * x) / x;
}

export function useUnitConversion() {
	const store = useStore();
	return {
		feetToMeters(value, precision) {
			const m = new Measurement({ value, units: 'feet' });
			if (store.settings.metricUnits) {
				m.value = round(value * 0.3048, typeof precision === 'number' ? precision : 0);
				m.units = 'meters';
			}
			return m;
		},
		mmToInches(value, precision) {
			const m = new Measurement({ value, units: 'mm' });
			if (store.settings.metricUnits) {
				m.value = round(value / 25.4, typeof precision === 'number' ? precision : 0);
				m.units = 'inches';
			}
			return m;
		},
		inchesToMm(value, precision) {
			const m = new Measurement({ value, units: 'inches' });
			if (store.settings.metricUnits) {
				m.value = round(value * 25.4, typeof precision === 'number' ? precision : 0);
				m.units = 'mm';
			}
			return m;
		},
		inchesPerMinuteToMmPerSecond(value, precision) {
			const m = new Measurement({ value, units: 'inch/min' });
			if (store.settings.metricUnits) {
				m.value = round(value * 25.4 / 60, typeof precision === 'number' ? precision : 0);
				m.units = 'mm/sec';
			}
			return m;
		},
		poundsToKiligrams(value, precision) {
			const m = new Measurement({ value, units: 'lbs' });
			if (store.settings.metricUnits) {
				m.value = round(value * 0.4536, typeof precision === 'number' ? precision : 0);
				m.units = 'kg';
			}
			return m;
		},
		tonsToMetricTons(value, precision) {
			const m = new Measurement({ value, units: 'US tons' });
			if (store.settings.metricUnits) {
				m.value = round(value * 0.9072, typeof precision === 'number' ? precision : 0);
				m.units = 'tonnes';
			}
			return m;
		},
		tonsToKilonewtons(value, precision) {
			const m = new Measurement({ value, units: 'US tons' });
			if (store.settings.metricUnits) {
				m.value = round(value * 8.896, typeof precision === 'number' ? precision : 0);
				m.units = 'kN';
			}
			return m;
		},
	};
}
