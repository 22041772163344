<template>
	<transition name="toggle-fade">
		<div class="inactivity" v-if="open">
			<div class="overlay" :style="overlayStyle"></div>
			<template v-if="state === states.warning">
				<div class="content">
					<div class="heading">Are you <span class="inl-bl">still there?</span></div>
					<div class="text">Click or touch the screen <span class="inl-bl">to stay on the page.</span></div>
				</div>
				<div class="countdown" :style="countdownStyle"></div>
			</template>
		</div>
	</transition>
</template>

<script setup>
import { useStore } from '@/store';
import { computed, onUnmounted, ref } from 'vue';
import debounce from 'lodash.debounce';
import { watch } from 'vue';
import { useRouter } from 'vue-router';

const windowEvents = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const documentEvents = ['touchstart', 'pointerdown'];
const inactiveLength = 5 * 60 * 1000; // milliseconds
const warningLength = 10 * 1000; // milliseconds
const resettingLength = 200; // milliseconds

const store = useStore();
const router = useRouter();
const states = {
	idle: 'idle',
	inactive: 'inactive',
	warning: 'warning',
	resetting: 'resetting',
};
const state = ref('closed');
const open = computed(() => state.value === states.warning || state.value === states.resetting);
const overlayStyle = computed(() => ({
	animationName: state.value === states.resetting ? 'fadeOut' : undefined,
	opacity: state.value === states.resetting ? '0' : undefined,
	animationDuration: resettingLength + 'ms',
}));
const countdownStyle = computed(() => ({
	animationDuration: warningLength + 'ms',
}));
const onHomePage = computed(() => router.currentRoute.value.fullPath === '/');

let timeout;
const restartInactivityTimer = debounce(() => {
	state.value = states.inactive;
	clearTimeout(timeout);
	timeout = setTimeout(timeoutExpired, inactiveLength);
}, 50);

function timeoutExpired() {
	clearTimeout(timeout);
	if (state.value === states.inactive) {
		if (onHomePage.value) {
			state.value = states.idle;
			const threshold = 2;
			if (window.scrollY > threshold || window.scrollX > threshold) {
				window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			}
		} else {
			state.value = states.warning;
			timeout = setTimeout(timeoutExpired, warningLength);
		}
	} else if (state.value === states.warning) {
		state.value = states.resetting;
		router.push('/');
		timeout = setTimeout(timeoutExpired, resettingLength);
	} else {
		state.value = states.idle;
	}
}

function startTracking() {
	restartInactivityTimer();
	windowEvents.forEach(x => window.addEventListener(x, restartInactivityTimer, { passive: true }));
	documentEvents.forEach(x => document.addEventListener(x, restartInactivityTimer, { passive: true }));
}
function stopTracking() {
	clearTimeout(timeout);
	windowEvents.forEach(x => window.removeEventListener(x, restartInactivityTimer));
	documentEvents.forEach(x => document.removeEventListener(x, restartInactivityTimer));
}

watch(() => store.settings.inactivityReset, x => x ? startTracking() : stopTracking(), { immediate: true });
onUnmounted(stopTracking);
</script>
