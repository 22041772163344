import { openDB } from 'idb';

export function openIdb() {
	return openDB('safandarley', 2, {
		// upgrade: idb, oldVersion, newVersion, transaction
		upgrade(idb, oldVersion) {
			if (oldVersion < 1) {
				idb.createObjectStore('formSubmissions', { keyPath: 'id', autoIncrement: true }).createIndex('type', 'type', { unique: false });
			}
			if (oldVersion < 2) {
				idb.createObjectStore('settings');
			}
		},
	});
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 */
export async function getAllAsDictionary(idb, storeName) {
	const object = {};
	let cursor = await idb.transaction(storeName).store.openCursor();
	while (cursor) {
		object[cursor.key] = cursor.value;
		cursor = await cursor.continue();
	}
	return object;
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @param {String} indexName
 */
export async function getAllIndexKeys(idb, storeName, indexName) {
	const keys = new Set();
	let cursor = await idb.transaction(storeName).store.index(indexName).openKeyCursor();
	while (cursor) {
		keys.add(cursor.key);
		cursor = await cursor.continue();
	}
	return Array.from(keys);
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @param {String} indexName
 */
export async function deleteByIndexKey(idb, storeName, indexName, indexKey) {
	let cursor = await idb.transaction(storeName, 'readwrite').store.index(indexName).openCursor(indexKey);
	while (cursor) {
		await cursor.delete();
		cursor = await cursor.continue();
	}
}
