<template>
	<teleport to="body">
		<transition name="toggle-fade" appear>
			<div v-if="open" class="modal-bg"></div>
		</transition>

		<transition name="toggle-scale" appear>
			<div v-if="open" class="prompt-contain">
				<div class="prompt-head">
					<div class="icon-contain">
						<img src="@/images/app-icon.png" />
					</div>
					<div class="content-contain">
						<span class="action">Install Septic Biz Man</span>
						<span class="desc">Required for full app functionality</span>
					</div>
				</div>
				<div class="prompt-body">
					<div class="android-warn" v-if="isAndroid">
						<div class="warn-head">
							<svg class="icon">
								<use xlink:href="#warn-triangle"></use>
							</svg>
							Google Play Store login required
						</div>
						<div class="warn-body">
							To properly install Septic Biz Man on Android devices, ensure you are logged into the
							<a class="play-store-link" target="_blank" href="https://play.app.goo.gl/?link=https://play.google.com/store">
								Google Play Store
								<svg class="icon">
									<use xlink:href="#external"></use>
								</svg>
							</a>
							on your device <strong>before</strong> installing this app.
						</div>
					</div>
					<div class="answer-buttons">
						<button @click="showPwaInstall()" class="btn-primary">Install App</button>
						<button @click="closeBanner()" class="btn-lt-grey">Not right now</button>
					</div>
				</div>
			</div>
		</transition>
	</teleport>
</template>

<script setup>
import { useStore } from '@/store';
import { computed } from 'vue';

const store = useStore();
const open = computed(() => store.showInstallBanner && !store.serviceWorkerUpdate);
const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

function closeBanner() {
	store.setCustomPwaBanner(false);
	localStorage.setItem('pwaUserChoice', 'no');
}
function showPwaInstall() {
	store.setCustomPwaBanner(false);
	store.installPrompt.prompt();

	store.installPrompt.userChoice.then((choiceResult) => {
		if (choiceResult.outcome === 'accepted') {
			store.showInstallButton = false;
		} else {
			localStorage.setItem('pwaUserChoice', 'no');
		}
	});
}
</script>
