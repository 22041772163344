import "@/scss/main.scss";
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		component: () => import('../views/HomePage.vue'),
		meta: { title: 'Home', },
	},
	{
		path: '/contact',
		component: () => import('../views/ContactPage.vue'),
		meta: { title: 'Inquiry', },
	},
	{
		path: '/:eBrake*',
		redirect: '/'
	},
	{
		path: '/E-Brake',
		component: () => import('../views/E-Brake/E-BrakePage.vue'),
		meta: { title: 'E-Brake', },
	},
	{
		path: '/E-Brake/50-130T',
		component: () => import('../views/E-Brake/50-130T.vue'),
		meta: { title: 'E-Brake 50-130T Ultra', },
	},
	{
		path: '/E-Brake/35-40T',
		component: () => import('../views/E-Brake/35-40T.vue'),
		meta: { title: 'E-Brake 35-40T Premium Ergonomic', },
	},
	{
		path: '/E-Brake/35-130T',
		component: () => import('../views/E-Brake/35-130T.vue'),
		meta: { title: 'E-Brake 35-130T Premium', },
	},
	{
		path: '/E-Brake/300T',
		component: () => import('../views/E-Brake/300T.vue'),
		meta: { title: 'E-Brake 300T Ultra Dual Drive', },
	},
	{
		path: '/E-Brake/160-200T',
		component: () => import('../views/E-Brake/160-200T.vue'),
		meta: { title: 'E-Brake 160-200T Ultra', },
	},
	{
		path: '/E-Brake/MiniCell',
		component: () => import('../views/E-Brake/MiniCell.vue'),
		meta: { title: 'E-Brake Mini-Cell', },
	},
	{
		path: '/:hBrake*',
		redirect: '/'
	},
	{
		path: '/H-BrakeHD/700-1000T',
		component: () => import('../views//H-BrakeHD/700-1000T.vue'),
		meta: { title: 'H-Brake HD 700-1000T', },
	},
	{
		path: '/H-iBrake',
		component: () => import('../views/H-iBrake/H-iBrakePage.vue'),
		meta: { title: 'H-iBrake', },
	},
	{
		path: '/H-iBrake/125-560T',
		component: () => import('../views/H-iBrake/125-560T.vue'),
		meta: { title: 'H-iBrake 125-560T', },
	},
	{
		path: '/H-iBrake/125T',
		component: () => import('../views/H-iBrake/125T.vue'),
		meta: { title: 'H-iBrake 125T', },
	},
	{
		path: '/M-Shear',
		component: () => import('../views/M-Shear.vue'),
		meta: { title: 'M-Shear', },
	},
	{
		path: '/MultiCell',
		component: () => import('../views/MultiCell.vue'),
		meta: { title: 'MultiCell', },
	},
	{
		path: '/:rBrake*',
		redirect: '/'
	},
	{
		path: '/R-Brake/130T-3100',
		component: () => import('../views/R-Brake/130T-3100.vue'),
		meta: { title: 'R-Brake 130T-3100', },
	},
	{
		path: '/RoboMate',
		component: () => import('../views/RoboMate.vue'),
		meta: { title: 'Robo Mate', },
	},
	{
		path: '/ToolMate',
		component: () => import('../views/ToolMate/ToolMatePage.vue'),
		meta: { title: 'Tool Mate', },
	},
	{
		path: '/ToolMate/E-BrakeC200T',
		component: () => import('../views/ToolMate/E-BrakeC200T.vue'),
		meta: { title: 'E-Brake C 200T Tool Mate', },
	},
	{
		path: '/ToolMate/H-BrakeTo320T',
		component: () => import('../views/ToolMate/H-BrakeTo320T.vue'),
		meta: { title: 'H-Brake up to 320T Tool Mate', },
	},
	{
		path: '/settings',
		component: () => import('../views/SettingsPage.vue'),
		meta: { title: 'Settings', },
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0 }
	},
});

router.beforeEach(() => {
	// Mimic service worker updating on navigation for SPA
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then(worker => worker.update());
	}
});

router.afterEach((to) => {
	document.title = (to.meta.title ? to.meta.title + ' | ' : '') + 'SafanDarley';
});

export default router
