<template>
	<div v-show="messages.length > 0" class="app-message-float">
		<div class="inner">
			<app-message v-for="message in messages" :key="message.id" :message="message" />
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from '@/store';
import AppMessage from './AppMessage.vue';

const store = useStore();
const messages = computed(() => store.messages);
</script>
